// ------------------------------
// (*￣︿￣) 新闻
// ------------------------------

.xw-list
    background-color: #FFF
    position: relative
    border: 1px solid $line
    padding: 10px 20px
    height: 122px
    width: 590px
    padding-left: 152px
    margin-bottom: 17px
    +fix(box-sizing, border-box)
    .xw-pic
        position: absolute
        top: 10px
        left: 20px
        img
            width: 100px
            height: 100px

    .xw-content
    .xw-title
        font-weight: bold
        margin: 10px 0
        height: 21px
        overflow: hidden
        a
            color: #555
    .xw-txt

        font-size: 12px
        line-height: 28px
        height: 56px
        overflow: hidden
        position: relative
        p
            color: #999

        .wx-time
            position: absolute
            right: 0
            bottom: 0
            color: $main
