// ------------------------------
// (*￣︿￣) 
// ------------------------------

.tj
    border: 1px solid $line
    background-color: #fff
.tj-title
    padding: 15px 0
    h4
        border-left: 3px solid $main
        line-height: 20px
        font-size: 14px
        color: #000
        padding-left: 10px
.tj-list
    border-top: 1px dashed $line
    padding: 10px
.tj-pic
    float: left
    width: 70px
    margin-right: 10px
    img
        width: 70px
        height: 70px
.tj-content
    overflow: hidden
    font-size: 12px
    line-height: 20px
    color: #999
    em
        font-style: normal
    a
        color: #555
    .tj-name, .tj-address
        margin-bottom: 5px
