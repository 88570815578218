// ------------------------------
// (*￣︿￣) 
// ------------------------------

$ja: #ff6c5c

.header
    height: 75px
    background-color: #FFF
    border-bottom: 1px solid $line
.logo
    a
        display: block
        height: 75px
        width: 120px
        font-size: 0
        line-height: 0
        +bg(logo)
        
@import nav

.join
    p
        display: inline-block
        font-size: 16px
        vertical-align: top
        line-height: 32px
        margin-top: ( 75px - 36 ) / 2
        margin-left: 32px
    .join-platform
        color: $main
        a
            color: $main
    .join-apply
        border: 2px solid $ja
        padding: 0 15px
        +br(32px)
        a
            display: block
            color: $ja