// ------------------------------
// (*￣︿￣) 
// ------------------------------


=filter($this, $is, $old)
    background-color: #FFF
    border: 1px solid $line
    padding-left: 20px
    margin-bottom: 20px
    dl
        border-top: 1px dashed $line
        padding: 7px 0
        &:first-child
            border-top: 0
            padding-bottom: 47px
        a
            color: #555
    dt
        width: 50px
    dt, .filter-all
        color: #000
        height: 24px
        line-height: 24px
        float: left
    dd
        a
            margin-right: 20px
            padding: 0 10px
            
            &:hover, &.on
                background-color: $main
                color: #fff
                // display: inline-block
                +br(3px)


    dd.filter-all
        width: 68px
        a
            margin: 0
            padding: 4px 13px
            // color: #fff
    
    dd.filter-val
        width: 1045px
        float: left
        line-height: 24px
        position: relative
        a
            display: inline-block

    .fv-list
        display: inline-block
        margin-right: 40px
        &:hover
            .fv-more
                z-index: 2
        &:hover, &.on
            .fv-more
                display: block
            & > a
                border: 1px solid $line
                border-bottom: 1px solid #f6f6f6
                line-height: 28px
                background-color: #F6F6F6
                z-index: 3
                color: #555
                i
                    +bg(aru, center)

        & > a
            display: inline-block
            position: relative
            border: 1px solid #fff
            line-height: 30px
            padding: 0 6px
            padding-bottom: 5px
            color: #555
            margin-right: 0
            border-bottom: 1px solid #f6f6f6
            // &.on, &:hover
                // border: 1px solid $line
                // border-bottom: 1px solid #f6f6f6
                // line-height: 28px
                // background-color: #F6F6F6
                // z-index: 3
                // i
                //     +bg(aru, center)
                
        i.icon-arrow
            display: inline-block
            vertical-align: middle
            +bg(ard, center)
            width: 11px
            height: 6px
            margin-left: 6px
            

    .fv-more
        display: none
        position: absolute
        left: 0
        top: 35px
        width: 100%
        +fix(box-sizing, border-box)
        border: 1px solid $line
        background-color: #f6f6f6
        line-height: 22px
        padding: 8px 10px
        a
            display: inline-block
            color: #555
            margin-bottom: 2px
            margin-right: 20px
            padding: 0 10px
            line-height: 24px

            &.on, &:hover
                background-color: $main
                color: #fff
                display: inline-block
                +br(3px)


.filter
    background-color: #FFF
    border: 1px solid $line
    padding-left: 20px
    margin-bottom: 20px
    dl
        border-top: 1px dashed $line
        padding: 7px 0
        &:first-child
            border-top: 0
        a
            color: #555
    dt
        width: 50px
    dt, .filter-all
        color: #000
        height: 24px
        line-height: 24px
        float: left
    dd
        a
            margin-right: 20px
            padding: 0 10px
            
            &:hover, &.on
                background-color: $main
                color: #fff
                // display: inline-block
                +br(3px)


    dd.filter-all
        width: 68px
        a
            margin: 0
            padding: 4px 13px
            // color: #fff
    
    dd.filter-val
        width: 1045px
        float: left
        line-height: 24px
        position: relative
        a
            display: inline-block

    .fv-list
        display: inline-block
        margin-right: 40px
        &:hover, &.on
            & > a
                border: 1px solid $line
                border-bottom: 1px solid #f6f6f6
                line-height: 28px
                background-color: #F6F6F6
                color: #555
                z-index: 3
                i
                    +bg(aru, center)

        & > a
            display: inline-block
            position: relative
            border: 1px solid #fff
            line-height: 28px
            padding: 0 6px
            padding-bottom: 3px
            color: #555
            margin-right: 0
            border-bottom: 1px solid #f6f6f6
            // &.on, &:hover
                // border: 1px solid $line
                // border-bottom: 1px solid #f6f6f6
                // line-height: 28px
                // background-color: #F6F6F6
                // z-index: 3
                // i
                //     +bg(aru, center)
                
        i.icon-arrow
            display: inline-block
            vertical-align: middle
            +bg(ard, center)
            width: 11px
            height: 6px
            margin-left: 6px
            

    .fv-more
        
        +fix(box-sizing, border-box)
        border: 1px solid $line
        background-color: #f6f6f6
        line-height: 22px
        padding: 8px 10px
        a
            display: inline-block
            color: #555
            margin-right: 20px
            padding: 0 10px
            line-height: 24px
            margin-bottom: 2px
            &.on, &:hover
                background-color: $main
                color: #fff
                display: inline-block
                +br(3px)


    .location
        padding-bottom: 0
    .filter-showdetail
        width: 1045px
        margin-left: 118px
        position: relative
        top: -1px
        margin-bottom: 8px
        & > div
            display: none
            &:first-child
                display: block