// ------------------------------
// (*￣︿￣) 
// ------------------------------


.pagination, #pagelist
    margin: 30px 0
    &.tac   
        text-align: center
    &.tal
        text-align: left
    &.tar
        text-align: right
    ul
        margin-right: 20px
    ul, li
        display: inline-block
        .current, .page_total, .page_total2
            padding: 0 12px
        


        li
            border: 1px solid $line
            background-color: #FFF
            height: 30px
            line-height: 30px
            margin-left: -1px
            a, span
                display: block
                padding: 0 12px
                color: #555
            &.current, a:hover, &.am-active
                background-color: $main
                color: #fff
                span
                    color: #fff

            &.page_total2 
                padding: 0 10px


