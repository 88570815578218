// ------------------------------
// (*￣︿￣) 
// ------------------------------

.sort
    margin-bottom: 20px
.sort-top
    margin-bottom: 5px
    em
        font-style: normal

.sort-tab
    border: 1px solid $line
    background-color: #FFF
    font-size: 0
    a
        padding: 14px 40px
        display: inline-block
        position: relative
        line-height: 20px
        height: 20px
        font-size: 12px
        margin-left: -1px
        color: #555
        &:after
            content: ""
            width: 1px
            height: 20px
            position: absolute
            right: 0
            top: 14px
            background-color: #999999
        &:hover, &.on
            background-color: $main
            color: #fff
            &:after
                background-color: $main

        span
            margin-right: 5px