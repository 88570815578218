// ------------------------------
// (*￣︿￣) 
// ------------------------------

@import ../core/var

input, select, textarea
    +fix(box-sizing, border-box)
input[type=submit], button, input[type=button]
    border: 0 none
