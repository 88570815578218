// ------------------------------
// (*￣︿￣) 
// ------------------------------


.H_date 
    background: #fff
    padding: 25px 45px

.H_date .isokweekend 
    background: #fff297


.H_d2 
    font-size: 22px
    // width: 460px
    text-align: center
    // font: 16px/18px "Microsoft YaHei"
    color: $main
    font-weight: bold
    font-family: Times 


.H_d2 strong 
    color: #F00


.H_top 
    margin-bottom: 5px
    padding: 0 3px
    text-align: center


.H_top span 
    display: inline-block
    line-height: 40px


.H_top span a 
    color: #444


.H_top .H_d1 
    // width: 90px
    float: left


.H_top .H_d3 
    // width: 90px
    float: right
    text-align: right


.H_weeks 
    border: 1px solid #fff
    border-collapse: separate
    border-spacing: 5px
    td, th
        border-collapse: separate
        display: table-cell

.H_weeks th 
    border: 1px solid #DDDDDD
    color: #666
    +br(3px)
    &.red
        color: #f00


.H_weeks td 
    // border: 1px solid #ffcc66
    border: 0
    height: 60px
    background: #F5F5F5
    color: #666
    cursor: pointer
    padding: 8px


.H_weeks td span 
    color: #f60


.H_weeks td.no 
    border: 1px solid #fff
    background: #fff
    cursor: default


.H_weeks td.ful 
    // border: 1px solid #eeeeee
    border: 0
    background: #f9f9f9
    color: #999 !important
    cursor: default


.H_weeks td.ful span 
    color: #b7b7b7


.H_weeks p 
    line-height: 18px
    position: relative


.H_weeks p a 
    display: none


.H_weeks p.fan a 
    display: block
    background: url(../images/fan_xian.png) no-repeat left center
    position: absolute
    right: 0
    top: -2px
    width: 13px
    height: 13px
    margin: 3px 5px 0 0
    cursor: help
