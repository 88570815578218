// ------------------------------
// (*￣︿￣) 点评
// ------------------------------

=dp($w: auto, $h: auto)
    .dp-list
        height: $h
        width: $w

.dp-list
    background-color: #FFF
    border: 1px solid $line
    padding: 20px
    padding-bottom: 10px
    padding-left: 152px
    position: relative
    margin-bottom: 20px
    +fix(box-sizing, border-box)
    .dp-user
        position: absolute
        left: 20px
        top: 20px
        width: 100px
        text-align: center
        & > *
            margin-bottom: 10px
    .dp-name
        color: $main
        a
            color: $main
    .dp-avatar
        border: 4px solid $line
        +br(50%)
        overflow: hidden
        img
            width: 92px 
            height: 92px
            +br(50%)
            display: inline-block
    .dp-img
        img
            width: 48px
            height: 48px
            display: inline-block


    .dp-content
        min-height: 172px
    .dp-kz
        font-weight: bold
        color: $main
        line-height: 26px
        margin-bottom: 5px
        a
            color: $main
    .dp-info
        margin-bottom: 5px
        p
            display: inline-block
            em
                font-style: normal

    .dp-txt
        position: relative
        line-height: 30px
        margin: 15px 0
        a.more
            position: absolute
            right: 0
            bottom: 0
            color: $main
            z-index: 4
        .hidemore
            color: $main

    .dp-img
        margin-bottom: 15px
        font-size: 0
        .dp-showimg, .dp-showmore
            display: inline-block
            vertical-align: middle
        img
            margin-right: 10px
            vertical-align: top
        .dp-showmore
            font-size: 12px

            a
                color: $main
    .dp-time
        color: #999
        font-size: 12px

    .dp-timereply
        position: relative

    .dp-time
        line-height: 30px
        font-size: 12px

    .dp-reply
        position: absolute
        right: 10px
        top: 5px
        line-height: 30px
        font-size: 12px
        a
            color: #999
            padding-left: 20px
            +bg(reply, left)

    .more
        background-color: #FFF
        padding-left: 10px




.dp-add
    display: none
    textarea
        width: 100%
        height: 95px
        padding: 5px
        +fix(box-sizing, border-box)
        border: 1px solid $line
        +fix(box-shadow, inset 2px 2px 4px #eee)
        vertical-align: top
    input[type=submit]
        background-color: $main
        color: #fff
        padding: 0 40px
        height: 38px
        +br(5px)



.dp-replytotal
    background-color: #f5f5f5
    padding: 10px
    margin: 15px

.dp-top
    font-size: 22px
    border-bottom: 1px dashed #ddd
    margin: 15px
    line-height: 2


.dp-details 
    

// .dp-txt
//     height: 60px
//     overflow: hidden
//     background-color: #FFF

// .dp-txt.on
//     overflow: visible
// .dp-txt-all
//     position: absolute
//     background-color: #FFF
//     z-index: 3
//     border-bottom: 1px solid $line



.dpscroll
    padding-bottom: 20px
    .dp-list
        margin-bottom: 0

.xwscroll
    padding-bottom: 17px
    .xw-list
        margin-bottom: 0