// ------------------------------
// (*￣︿￣) 
// ------------------------------

.search
    border: 1px solid $line
    background-color: #FFF
    padding: 7px 0
    margin: 10px 0

.none
    display: none

.search-zone
    width: 153px

#nowCity 
    line-height: 45px
    position: relative
    float: left
    font-size: 12px
    border-right: 1px solid $line

#nowCity > a 
    font-size: 16px
    color: #333
    display: block
    z-index: 1002
    position: relative
    padding: 0 30px
    // border: 1px solid transparent


#nowCity > a span 
    max-width: 80px
    overflow: hidden
    white-space: nowrap
    height: 45px
    color: $main
    display: inline-block
    vertical-align: middle

#nowCity.active > a, #nowCity:hover > a 
    // box-shadow: 0 0 8px 0 #ccc
    // border: 1px solid #ddd
    background: #fff
#nowCity.active > a:after, #nowCity:hover > a:after
    // content: ''
    position: absolute
    top: 50px
    left: 0
    height: 20px
    width: 112%
    background: #fff
    z-index: 1003
#nowCity > a i 
    // background-position: -15px -10px
    display: inline-block
    +bg(ard)
    margin-left: 12px
    width: 11px
    height: 6px
    vertical-align: baseline
#nowCity.active > a i, #nowCity:hover > a i 
    // background-position: -29px -9px
    +bg(aru)
#nowCity.active #showChooseCity , #nowCity:hover #showChooseCity 
    display: block

#showChooseCity 
    border: 1px solid #ddd
    width: 385px
    padding: 0 15px 0 20px
    line-height: 28px
    // box-shadow: 0 0 8px 0 #ccc
    position: absolute
    left: -1px
    top: 44px
    background: #fff
    z-index: 1001
    display: none

.recom-city 
    z-index: 3
    width: 385px
    background: #fff

.rcc-ban 
    border-bottom: 1px solid #ddd
    height: 25px
    padding-top: 25px
    _overflow: hidden
    // width: 377px
.rcc-ban li 
    float: left
    padding: 0 11px
    height: 24px
    line-height: 24px
    cursor: pointer

.rcc-ban li a 
    color: #333

.rcc-ban li a:hover 
    color: #f60
    text-decoration: none

.rcc-ban .rcc-at 
    border: 0
    border-bottom: 2px solid $main
    color: $main
    font-weight: 700

.rcc-ban .rcc-at span 
    color: $main

.rcc-con 
    padding: 10px 0 10px 5px
    // width: 380px
    overflow: hidden

.rcc-con > span,.rcc-con div span span 
    color: #666
    float: left
    margin-right: 38px
    margin-bottom: 8px
    white-space: nowrap
    transition: all ease .2s
    line-height: 20px
    cursor: pointer

.rcc-con > span:hover 
    color: #f60

.rcc-con .rcc-at 
    color: #fff
    background: #8c96a2

.rcc-con .rcc-at:hover 
    color: #fff

.rcc-con > div:after 
    content: '.'
    display: block
    clear: both
    line-height: 0
    height: 0
    visibility: hidden

.rcc-con div > span 
    display: block
    color: #f60
    float: left
    width: 350px

.rcc-con div > span:first-child 
    width: 12px
    font-size: 14px
    margin-right: 15px
    line-height: 20px

