// ------------------------------
// (*￣︿￣) 
// ------------------------------


.zx-list
    padding: 15px 20px
    border-bottom: 1px solid $line
.zx-top
    line-height: 25px
    *
        display: inline-block
    .zx-title
        font-size: 14px
        font-weight: bold
    time
        float: right
        color: #999

.zx-user
    line-height: 25px

.zx-reply
    margin-top: 5px
    em
        font-style: normal
    background-color: #F6F6F6
    padding: 10px 15px
    line-height: 22px

