// ------------------------------
// (*￣︿￣) 
// https://github.com/thx/cube/blob/gh-pages/src/scss/neat.scss
// ------------------------------

body, dl, dd, /* dt, 默认没边距 */ ul, ol, /* li, 默认没边距 */ h1, h2, h3, h4, h5, h6, pre, /* code, 默认没边距 */ form, fieldset, legend, input, textarea, optgroup, p, blockquote, figure, hr, menu, dir, thead, tbody, tfoot, th, td /* th, td 默认 padding:1px */
    margin: 0
    padding: 0

ul, ol /* 非大面积文字排版网站通常不需要列表项，如果需要可单独设置 */
    list-style-type: none
    list-style-image: none

a
    text-decoration: none /* 去除链接默认的下划线，提高文字可读性 */
    &:active, &:hover
        outline: 0 none /* 去掉点击时的焦点框，同时保证使用键盘可以显示焦点框 */
    // &:focus /* 统一 Chrome 和 Safari 的焦点框样式 */  /* Chrome 中 thin 关键字放大页面后焦点框不会放大 http://jsbin.com/ehakom/1 */  /* Firefox 中 box-shadow 会导致焦点框位置偏移，需用「outline-offset」修正 */
        // outline: 1px dotted

    // &:active /* 去掉 IE 10+ 点击链接时的灰色背景 */
        // background-color: transparent

body, button, input, select, textarea /* 防止表单元素中「font-family」不能继承 */
    font-family: 'helvetica neue',arial,'hiragino sans gb',stheiti,'wenquanyi micro hei','\5FAE\8F6F\96C5\9ED1','\5B8B\4F53',sans-serif
    -ms-text-autospace: ideograph-alpha ideograph-numeric ideograph-parenthesis
    text-spacing: ideograph-alpha ideograph-numeric ideograph-parenthesis

h1, h2, h3, h4, h5, h6 
    font-weight: normal


hr, /* 修正 Firefox 和其他浏览器之间的差异 */
    box-sizing: content-box
    height: 0
mark 
    background-color: #D2E5FF
    color: #000
b, strong 
    font-weight: bold /* Firefox3+，Safari4/5 和 Chrome 中统一设置为粗体 */
small
    font-size: 85.7% /* 中文网页<small>元素字号小于 12px 不易阅读 */  /* 12/14=0.8571428571 */

table, /* 合并单元格边框 */
    border-collapse: collapse
    border-spacing: 0

legend 
    border: 0 none /* 修正 IE 6-9 中颜色不能继承的问题 */
    white-space: normal /* 修正 Firefox3 中文字不换行的问题 */
    *margin-left: -7px /* 修正 IE6-7 中怪异的对齐方式 */


button, input, select, textarea 
    font-family: inherit /* 修正所有浏览器中字体不继承的问题 */
    font-size: 100% /* 修正所有浏览器中字号不继承的问题 */
    margin: 0 /*  修正 Firefox 3+， Safari5 和 Chrome 中外边距不同的问题 */
    vertical-align: baseline /* 改善在所有浏览器下的垂直对齐方式 */
    *vertical-align: middle /* 改善在所有浏览器下的垂直对齐方式 */

input, button 
    *overflow: visible /* 修正 IE7 随着字数增加边距不断增加的问题 */

button, select /* 统一各浏览器「text-transform」不会继承的问题 */  /* http://jsbin.com/iqecic/1/edit */  /* http://tjvantoll.com/2012/07/10/default-browser-handling-of-the-css-text-transform-property/ */
    text-transform: none

button, html input[type="button"], /* 1. 避免 Android 4.0.* 中的 WebKit bug ，该bug会破坏原生的 */ input[type="reset"], input[type="submit"] 
    -webkit-appearance: button /* 更正 iOS 中无法设置可点击的「input」的问题 */
    cursor: pointer /* 统一其他类型的「input」的光标样式 */

button[disabled], input[disabled]  /* 重置按钮禁用时光标样式 */
    cursor: default


input[type="checkbox"], input[type="radio"] 
    box-sizing: border-box /* 修正 IE 8/9 box-sizing 被设置为「content-box」的问题 */
    padding: 0 /*  移除 IE 8/9 中多余的内边距 */
    *height: 13px /* 移除 IE7 中多余的内边距(IE6 中任然存在) */
    *width: 13px /* 移除 IE7 中多余的内边距(IE6 中任然存在) */


input[type="search"] 
    -webkit-appearance: textfield /* 修正 Safari 5 和 Chrome 中「appearance」被设置为「searchfield」的问题 */
    box-sizing: content-box /* 修正 Safari 5 和 Chrome 中「box-sizing」被设置为 「border-box」的问题 */


img 
    border: 0 none /* 去除 IE6-9 和 Firefox 3 中 a 内部 img 元素默认的边框 */
    width: auto\9 /* 修正 IE8 图片消失bug */
    // height: auto /* 防止 img 指定「height」时图片高度不能按照宽度等比缩放，导致图片变形 http://jsbin.com/aJoTUca/2 */
    max-width: 100% /* 让图片支持响应式 */
    vertical-align: top/* 去除现代浏览器图片底部的空隙 */
    -ms-interpolation-mode: bicubic /* 修复 IE7 图片缩放失真 */


/* 如果需要隐藏清除按钮需要加上 input[type="search"]::-webkit-search-cancel-button */
input[type="search"]::-webkit-search-decoration /* 移除 OS X 中 Safari5 和 Chrome 搜索框内侧的左边距 */
    -webkit-appearance: none

button::-moz-focus-inner, input::-moz-focus-inner  /* 移除 Firefox 3+ 的内边距 */
    border: 0
    padding: 0


input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button  /* 修正 Chrome 中 input [type="number"] 在特定高度和 font-size 时, 下面一个箭头光标变成「cursor: text」 @demo: http://jsfiddle.net/FFXEc/ 动画演示：http://gtms04.alicdn.com/tps/i4/T18kd8FCtaXXc_FhcF-330-350.gif */
    height: auto

textarea 
    overflow: auto /* 移除 IE6-11 中默认的垂直滚动条 */
    resize: vertical /* 禁止水平拖动，防止破坏布局 */


select:disabled option:checked, option:disabled:checked  /* 修正 Chrome 30- option 中文字无法显示的问题  http://jsbin.com/avujas/1/edit */
    color: #D2D2D2

/* 修正 Safari 3+, Chrome 1+ Placeholder 居中问题 */
@media screen and (-webkit-min-device-pixel-ratio:0)  
    input 
        line-height: normal !important
  

input::-moz-placeholder, textarea::-moz-placeholder  /* 修正 Firefox 19+ Placeholder 设置了opacity 的问题 */
    color: darkGray
    opacity: 1

label 
    cursor: pointer  /* label 元素给予手型，暗示此处可点击 */

select[size], select[multiple], select[size][multiple] /*  统一 select 样式, Firefox 中有 padding:1px 0  http://jsbin.com/avujas/1/edit */
    border: 1px solid #AAA
    padding: 0


// ------------------------------
// (*￣︿￣) html5
// ------------------------------

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary  /* 修正未定义为「block」的元素 */
    display: block

audio, canvas, video, progress 
    display: inline-block /* 修正未定义为「inline-block」的元素 */
    vertical-align: baseline /* 修正 Chrome、Firefox、Opera 中 「progress」元素 vertical-align 默认值不一致 */

audio:not([controls]) 
    display: none /* 防止现代浏览器将没有「controls」属性的 「audio」元素显示出来 */
    height: 0 /* 去掉 iOS 5 中多余的高度 */



[hidden], template  /* 修复 IE 7/8/9，Firefox 3 和 Safari 4 中 「hidden」属性不起作用的问题 */  /* 在IE、Safari、Firefox 22- 中隐藏「template」元素 */
    display: none

[draggable], /* 为可拖动元素添加拖动的光标 http://jsbin.com/apavod/1/edit */
    cursor: move

 
abbr, acronym
    border-bottom: 1px dotted /* 修正「abbr」元素在 Firefox 外其他浏览器没有下划线的问题 */
    cursor: help /* 添加问号光标，明确语义 */

dfn
    font-style: italic /* 修正 Safari5 和 Chrome 中没有样式的问题 */
code, kbd, pre, samp /* 统一代码的字体设置 */  /* 字体要能明确区分数字 0 和字母 o */  /* Mac 优先使用 Monaco，Windows 优先使用 Consolas */  /* XP自带 Courier New */  /* Windows 7开始自带的 Consolas */  /* Mac上自带的Monaco，Osaka-Mono */
    font-family: monaco, menlo, consolas, 'courier new', courier, monospace
pre 
    white-space: pre
    white-space: pre-wrap /* IE 6-7 不支持 pre-wrap */
    word-wrap: break-word
    overflow: auto /* pre 标签应当包含滚溢出 */
q 
    quotes: none /* IE 6-7 不支持 quotes 属性, 现代浏览器去除 quotes 内容 */
    &:before, &:after /* Safari 4 不支持<q>标签 */
        content: ""
        content: none

sub, sup /* 防止所有浏览器中的<sub>和<sup>影响行高 */  /* http://jsbin.com/usoyal/1/edit */
    font-size: 75%
    line-height: 0
    position: relative
    vertical-align: baseline
sup 
    top: -0.5em
sub 
    bottom: -0.25em

svg:not(:root) 
    overflow: hidden /* 修复 IE9 中的「overflow」的怪异表现 */


html 
    text-size-adjust: 100% /* 防止 iOS 横屏字号放大，同时保证在PC上 zoom 功能正常 */
    font-size: 62.5% /* 10/16=62.5% */

body
    font-size: 14px
    line-height: 1.5
    word-wrap: break-word // 英文断行
    webkit-tap-highlight-color: rgba(0,0,0,0)

h1
    font-size: 36px
h2
    font-size: 30px
h3
    font-size: 22px
h4
    font-size: 18px
h5
    font-size: 14px
h6
    font-size: 12px