// ------------------------------
// (*￣︿￣) 
// ------------------------------

@import core/var
@import m/reset
@import m/color
@import m/form
@import m/table
// @import m/btn
@import m/class

html, body
    color: #555
    background-color: #f6f6f6
a
    color: $blue

input[type=button]
    background-color: $main
    padding: 0 20px
    height: 30px
    color: #fff
    -ms-border-radius: 5px
    border-radius: 5px

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill 
    background-color: rgb(250, 255, 255) !important
    background-image: none !important
    color: rgb(0, 0, 0) 


.w
    width: 1200px
    margin: 0 auto
.mb20
    margin-bottom: 20px
.white
    background-color: #FFF
.cside
    width: 250px
.cmain
    width: 930px

.star
    width: 112px
    height: 18px
    line-height: 1
    display: inline-block
    vertical-align: middle
    +bg(star)
@for $i from 0 to 5
    .star#{$i}
        background-position: #{0} -(18px * $i) 
.icon-map
    display: inline-block
    vertical-align: middle
    width: 14px
    height: 18px
    +bg(map)
.icon-qj
    display: inline-block
    vertical-align: middle
    width: 26px
    height: 22px
    +bg(qjh, center)
.on .icon-qj, a:hover .icon-qj
    +bg(qj, center)
.icon-tp
    display: inline-block
    vertical-align: middle
    width: 23px
    height: 21px
    +bg(tph, center)
.on .icon-tp, a:hover .icon-tp
    +bg(tp, center)
.icon-arrow-down
    display: inline-block
    vertical-align: middle
    +bg(ard, center)
    width: 11px
    height: 6px

.icon-arrow-up
    display: inline-block
    vertical-align: middle
    +bg(ard, center)
    width: 11px
    height: 6px
.icon-sort-up
    display: inline-block
    vertical-align: middle
    width: 8px
    height: 8px
    +bg(su)

.icon-sort-down
    display: inline-block
    vertical-align: middle
    width: 8px
    height: 8px
    +bg(sd)



@import c/header
@import c/footer
@import c/breadcrumb
@import c/pagination

@import c/dp
@import c/xw
.dp, .xw
    width: 590px

.dp
    +dp(590px, 260px)
.city-dp    
    +dp(590px, 260px)

.city-dp, .city-xw
    margin-left: -20px
    & > *
        margin-left: 20px

@import c/search
@import c/filter


// ------------------------------
// (*￣︿￣) 01
// ------------------------------

.hot-search
    display: none
    background-color: #FFF
    width: 440px
    border: 1px solid #E7E7E7
    border-top: 0
    +fix(border-radius, 3px 0 3px 3px)
    +fix(box-sizing, border-box)
    position: relative
    z-index: 9
.hs-top
    background-color: #F2F2F2
    line-height: 35px
    padding: 0 10px
    position: relative
.hs-close
    display: inline-block
    line-height: 0
    font-size: 0
    color: transparent
    vertical-align: middle
    width: 16px
    height: 16px
    +bg(gb,center)
    position: absolute
    right: 10px
    top: 9px
    cursor: pointer
.hs-tab
    margin: 10px
    font-size: 0

    a
        display: inline-block
        font-size: 14px
        padding: 0 10px
        line-height: 26px
        color: #222
        border-bottom: 2px solid $line
        &:hover, &.on
            color: $main
            border-color: $main
.hs-content
    margin: 15px
    & > *
        display: none
        &:first-child
            display: block
    a
        width: 68px
        float: left
        line-height: 22px
        margin-bottom: 6px
        font-size: 13px
        color: #555
        &:hover, &.on
            color: $main

.ac_results
    background-color: #FFF
    border-radius: 3px 3px 0 0
    li
        padding: 3px 10px
        cursor: pointer

.slickSearch
    height: 600px
    position: relative
    .slick
        overflow: hidden
    .slick-prev
        position: absolute
        left: 50px
        top: 50%
        +bg(p, center)
        width: 80px
        height: 80px
        background-color: rgba(0,0,0,0.3)
        outline: none
        line-height: 0
        font-size: 0
        -ms-border-radius: 50%
        border-radius: 50%
        transition: all .3s
        &:hover
            background-color: rgba(0,0,0,0.4)
    .slick-next
        position: absolute
        right: 50px
        top: 50%
        +bg(r, center)
        width: 80px
        height: 80px
        background-color: rgba(0,0,0,0.3)
        outline: none
        line-height: 0
        font-size: 0
        -ms-border-radius: 50%
        border-radius: 50%
        transition: all .3s
        &:hover
            background-color: rgba(0,0,0,0.4)

.home-search 
    position: absolute
    bottom: 0
    width: 100%
    height: 110px
    +bgo(0.5)
    form
        margin: 0 130px

.form-search 

    .radio  
        font-size: 0
        input[type=radio]
            vertical-align: middle
            margin-right: 3px
        label
            font-size: 14px
            margin: 12px 0
            display: inline-block
            margin-right: 30px
            color: #fff
    input[type=text], input[type=submit]
        height: 46px
        float: left
        padding: 0 5px
    input[type=text].hst
        width: 440px
        border: 1px solid $line
        +br(5px 0 0 5px)
        +bg(l, 405px, #fff)
    input[type=text].hsd
        border: 1px solid $line
        margin-left: -1px
        width: 188px
        +bg(d, 146px, #fff)
    input[type=submit]
        background-color: $main
        width: 120px
        +br(0 3px 3px 0)
        font-size: 16px
        color: #fff

.search-in
    float: left
    input[type=text].hst, input[type=text].hsd
        border: 1px solid $line
        border-width: 0 1px 0 0
        height: 45px
        margin: 0
        +br(0)
    input[type=text].hst
        // width: 360px
        width: 340px
        +bg(l, 305px, #fff)
    input[type=text].hsd
        width: 245px
        +bg(d, 200px, #fff)
    input[type=submit]
        +br(3px)
        margin-left: 70px
        margin-top: 4px
        height: 38px



.home-white
    background-color: #FFF
    padding-bottom: 40px
.hbz
    border: 1px solid $line
    background-color: #F6F6F6
.home-title 
    text-align: center
    padding-top: 60px
    margin-bottom: 48px
    h2
        font-size: 0
        line-height: 0
        height: 36px
    p
        font-size: 18px
        line-height: 1
        margin-top: 16px
        color: #999
    .kztj
        +bg(kztj, center)
    .ztjx
        +bg(ztjx, center)

.hot-city
    font-size: 0
    margin-bottom: 30px
    a
        font-size: 14px
        display: inline-block
        padding: 0 24px
        color: #555
        line-height: 36px
        position: relative
        border-bottom: 2px solid #fff
        &:hover, &.on
            color: $main
            border-color: $main

        &:before
            position: absolute
            left: 0
            top: 12px
            width: 1px
            height: 12px
            background-color: #CCCCCC
            content: ""

        &:first-child:before
            display: none

.hot-kztj
    margin-bottom: 40px
    .hot-kztjcontent
        display: none
    & > div:first-child
        display: block
    a
        float: left
        margin-left: 22px
        margin-bottom: 20px
        overflow: hidden
        position: relative
   

        &:hover
            .hk-detail
                height: 100px
        .hk-detail
            position: absolute
            bottom: 0
            width: 100%
            color: #fff
            height: 45px
            background-color: rgba(0,0,0,0.5)
            transition: all .3s

        .hk-main
            height: 45px
            padding: 0 10px
            line-height: 45px
            border-bottom: 1px solid #ccc
            .hk-title
                font-size: 16px
                float: left
            .hk-price
                color: #f60
                float: right
        .hk-more
            margin: 10px
            color: #ddd
            line-height: 18px
            max-height: 36px
            overflow: hidden
            font-size: 12px
        img
            transition: all .5s

            &:hover
                transform: scale(1.05)
    .nom
        margin-left: 0

.home-step
    height: 261px
    width: 100%
    +bgj(home-step, center)

.theme-jx

    a
        float: left
        margin-bottom: 20px
        overflow: hidden
        img
            transition: all .5s
            &:hover
                transform: scale(1.05)
.theme-left
    width: 814px
    a
        margin-right: 20px


.dpxw
    margin-bottom: 60px
.txt-title  
    text-align: center
    padding-top: 45px
    padding-bottom: 10px
    h2
        font-size: 36px
    a
        color: #999


// ------------------------------
// (*￣︿￣) 02
// ------------------------------

.city-white
    background-color: #FFF
    border-top: 1px solid $line
    border-bottom: 1px solid $line
    padding: 78px 0
.m7
    margin: 70px 0

.city-title 
    text-align: center
    margin-bottom: 48px
    h2
        font-size: 0
        line-height: 0
        height: 36px
    .rmtj
        +bg(rmtj, center)
    .dpfx
        +bg(dpfx, center)
    .kzxw
        +bg(kzxw, center)

@import c/pt
.hot-tj
    margin-left: -30px
    +pt(30px, 250px)

.city-more
    margin-top: 40px
    a
        display: inline-block
        height: 40px
        line-height: 40px
        color: $main
        border: 1px solid $main
        padding: 0 40px
        -ms-border-radius: 40px
        border-radius: 40px

// ------------------------------
// (*￣︿￣) 03
// ------------------------------

@import c/sort

.kz-list
    border: 1px solid $line
    background-color: #FFF
    margin-bottom: 20px
    padding: 20px


.kz-lpic
    float: left
    width: 300px
    margin-right: 22px
.kz-limg
    height: 180px
    position: relative
    img
        width: 100%
        height: 100%

.kz-3d
    position: absolute
    top: 5px
    left: 5px
    +br(50%)
    +bgo(.5)
    width: 44px
    height: 44px
    text-align: center
    line-height: 44px
    color: #fff
    font-size: 16px
.kz-lcomment
    margin-top: 10px
    line-height: 18px
    a
        float: right
        color: $blue

.kz-content
    float: left
    width: 560px
.kz-name
    margin-bottom: 5px
    span
        font-size: 14px
        color: #999
.kz-name
    font-size: 20px
    display: inline-block
    margin-right: 25px
    h1, h3, a, span
        display: inline-block
    a
        color: #555
    & > a
        color: $main
        margin-left: 14px
        font-size: 14px
.kz-address
    margin-bottom: 5px
    a
        margin-left: 14px
        span
            vertical-align: middle
            display: inline-block
            margin-left: 5px
.kz-linfo
    margin-bottom: 5px
    color: #999
    +tp(100%)

.kz-data
    table
        margin-bottom: 5px
        font-size: 12px
        th
            line-height: 28px
            border-bottom: 1px solid $line
        td
            padding-top: 5px
        em
            font: normal 18px/1.5  'MicroSoft YaHei'
.kz-more
    font-size: 12px
    cursor: pointer

.ykdp
    border-bottom: 1px solid $line
    margin-bottom: 20px
    h2
        font-size: 16px
        display: inline-block
        line-height: 36px
        margin-bottom: -1px
        border-bottom: 2px solid $main

@import c/server
@import c/tj



// ------------------------------
// (*￣︿￣) 04
// ------------------------------

.kz
    color: #555
    margin-bottom: 20px
    h1
        font-size: 30px
        // color: #000

.kz-info
    +bg(kz-fw, right)

.kz-qj-pic
    margin-bottom: 25px

.kz-qj, .kz-tp  
    height: 560px
    background-color: #f0f0f
.kz-qj-fx
    height: 484px
.kz-tab
    font-size: 0
    a
        border: 1px solid #CCCCCC
        font-size: 14px
        line-height: 38px
        display: inline-block
        width: 118px
        text-align: center
        color: #555
        position: relative
        &:first-child
            border-right: 0
        &.on, &:hover
            color: $main
            &:before
                position: absolute
                top: -1px
                left: 0
                width: 100%
                height: 3px
                background-color: $main
                content: ""

    i
        margin-right: 6px

.kz-facility
    text-align: right
    margin: 10px 0
    em
        font-style: normal
    span
        padding-left: 18px
        margin-left: 15px
        +bg(yes, left)



.kz-tp
    position: relative
.kz-tplist
    margin-left: -3px
    li
        float: left
        height: 185px
        width: 308px
        margin-bottom: 3px
        margin-left: 3px
        background-position: center center
        &:first-child
            width: 619px
            height: 373px

.kz-tpmore
    position: absolute
    bottom: -40px
    padding: 10px 0
    cursor: pointer

.kz-details
    border: 1px solid $line
    background-color: #FFF
    margin-bottom: 20px

.kz-dtab
    font-size: 0
    border-top: 2px solid $main
    border-bottom: 1px solid $line
    top: -2px
    position: relative
    a
        display: inline-block
        font-size: 14px
        padding: 0 30px
        line-height: 42px
        border-bottom: 1px solid $line
        border-right: 1px solid $line
        color: #555
        position: relative
        margin-bottom: -1px
        &.on, &:hover
            background-color: $main
            color: #fff
            border-color: $main

.kz-myd
    float: right
    // line-height: 98px
    margin-top: 35px
    span
        margin-left: 15px
        em
            font-size: 20px
    
    .godp   
        margin-top: 10px
        a
            display: inline-block
            color: #fff
            +bg(dp, 20px)
            padding: 0 30px
            padding-left: 50px
            line-height: 35px
            background-color: $main
            // border: 1px solid #FF6C5C
            // color: #FF6C5C

            -ms-border-radius: 30px
            border-radius: 30px

.form-x
    padding: 5px 0
    .line
        margin-left: 22px
        font-size: 0
        display: inline-block
    label
        font-size: 12px
    input[type=text], input[type=date]
        width: 120px
        height: 28px
        padding-left: 5px
        border: 1px solid $line
        font-size: 12px
    input[type=submit]
        height: 28px
        background-color: $main
        font-size: 12px
        color: #fff

        +br(5px)
        padding: 0 10px

    .H_sub
        margin-left: 10px


.kz-roomlist
    tr
        border-top: 1px dashed $line
        position: relative
    th
        background-color: #EDEDED
        border: 1px solid #E7E7E7
        border-width: 1px 0
        height: 35px
        font-weight: bold
    td, th
        padding: 0 8px
        position: relative

    em
        font-size: 18px
        font-style: normal
    .btn
        padding: 0 15px
        height: 26px
        display: inline-block
        line-height: 26px
        background-color: $main
        +br(5px)
        color: #fff
        font-size: 12px

.view-price
    display: none
    position: absolute
    // left: -110px
    left: 50%
    max-width: 465px
    z-index: 9
    table
        text-align: center
        border: 1px dashed $main
        background-color: #FFF

        th
            background-color: #F6F6F6
            border: 1px dashed $main 
            border-width: 1px 0
            font-weight: normal
            font-size: 12px
        td
            padding: 10px 0
            color: $main
            text-align: center
            font-size: 16px

.kz-room
    height: 72px
    margin: 10px 0
    *
        display: inline-block
        vertical-align: middle
    &:after
        display: inline-block
        font-size: 0
        width: 0
        height: 100%
        vertical-align: middle
    img
        width: 120px
        height: 72px


.kz-box 
    border: 1px solid $line
    background-color: #FFF
    margin-bottom: 20px
.kz-title
    border-bottom: 1px solid $line
    h4
        display: inline-block
        padding: 0 12px
        border-bottom: 2px solid transparent
        font-size: 14px
        // color: $main
        margin-bottom: -1px
        margin-right: 15px
        line-height: 40px
        a
            color: #555
        &.on, &:hover
            cursor: pointer
            border-bottom-color: $main
            color: $main
            font-weight: bold
            a
                color: $main

    .godp
        height: 30px
        margin: 5px 10px
        a
            display: inline-block
            padding: 0 10px
            height: 30px
            line-height: 30px
            background-color: $main
            color: #fff
            text-align: center
            -ms-border-radius: 4px
            border-radius: 4px





.kz-txt
    padding: 20px
    line-height: 2

.kz-map
    margin: 15px

.kz-bdmap
    border: 1px solid $line
    width: 560px
    height: 300px

.kz-ss
    margin-left: 30px
    width: 300px
    p
        border-bottom: 1px solid $line
        a
            color: #555
            display: block
            +bg(g, 20px)
            padding: 7px 0
            padding-left: 70px
    p.on, p:hover
        a
            +bg(gh, 20px)
            color: $main



.map
    border: 1px solid $line
    height: 150px
    background-color: #FFF
    margin-bottom: 20px
    position: relative
    .map-bd
        height: 100%
    
    .map-big
        position: absolute
        bottom: 0
        right: 0
        line-height: 30px
        padding: 0 12px
        color: #fff
        +bgo(0.5)


.dp-one
    +dp(100%)
    .dp-kzinfo
        & > * 
            display: inline-block
        .dp-info
            float: right
            p
                margin-left: 10px

.kz-box
    .dp-one
        .dp-list
            margin-bottom: 0
            border: 0
            border-top: 1px solid $line
            &:first-child
                border-top: 0

@import c/zx
.zx-more
    margin: 20px
.zx-form
    padding: 20px
    textarea
        vertical-align: top
        width: 100%
        height: 100px
        border: 1px solid $line
        padding: 10px
        +fix(box-sizing, border-box)
        +fix(box-shadow, inset 0 0 5px #eee)
    input[type=submit]
        float: right
        background-color: $main
        padding: 8px 25px
        color: #fff
        font-size: 12px

@import c/tp

.myd
    border: 1px solid $line
    background-color: #FFF
    margin-bottom: 20px

.myd-top
    padding: 14px 0
    border-bottom: 1px dashed $line
    height: 20px
    line-height: 20px
    // border-left: 3px solid $main
    font-style: normal
    font-size: 12px
    position: relative
    &:before
        height: 20px
        width: 3px
        position: absolute
        top: 16px
        background-color: $main
        content: ""
        display: inline-block
    span
        padding-left: 10px
    em
        font-style: normal
        font-size: 20px

.slick-pj
    width: 185px
    margin: 10px auto
    font-size: 12px
    p, span
        font-size: 12px
        display: block
        line-height: 22px
    .slick-prev
        position: absolute
        left: -24px
        top: 0
        height: 66px
        width: 20px
        +bg(prev, center)
        font-size: 0
        line-height: 0
        color: transparent
        outline: none

    .slick-next
        position: absolute
        right: -24px
        top: 0
        height: 66px
        width: 20px
        +bg(next, center)
        font-size: 0
        line-height: 0
        color: transparent
        outline: none

// ------------------------------
// (*￣︿￣) 05
// ------------------------------

@import c/date

.kz-slick-for
    float: left
    width: 700px
    height: 484px
    img
        width: 700px
        height: 484px
        vertical-align: middle
.kz-slick-nav
    position: relative
    overflow: hidden
    float: right
    width: 170px
    height: 484px
    .kz-slick-content
        border: 0 !important
        height: 118px !important
        padding-bottom: 4px
    img
        width: 170px
        height: 118px
        vertical-align: middle
    .slick-prev, .slick-next
        position: absolute
        width: 100%
        height: 30px
        outline: none
        line-height: 0
        font-size: 0
    .slick-prev
        top: 0
        +bg(forprev)
    .slick-next
        bottom: 0
        +bg(fornext)


.for-kz
    width: 875px



.kz-yd
    margin-top: 41px
    border: 1px solid $line
    border-left: 0
    background-color: #FFF
    width: 324px - 50
    height: 484px - 56
    padding: 20px 25px
    padding-top: 36px

.kz-priceTotal
    background-color: #F6F6F6
    font-size: 14px
    color: #999
    padding: 10px
    i, em
        color: $main
        font-style: normal
    em
        font-size: 30px

.kz-indate
    margin: 0 15px
    margin-top: 36px
    .line
        width: 238px
        padding-left: 10px
        margin-bottom: 10px
    .bd
        border: 1px solid $line
    .submit
        padding: 0
        width: 250px
    label
        float: left
        width: 65px
        font-size: 12px
        line-height: 38px
    input[type=text]
        overflow: hidden
        border: 0
        width: 173px
        height: 38px
        +bg(kz-d, right)
        &.ydnum
            +bg(kz-n, right)
    input[type=submit]
        +bg(yd)
        height: 40px
        width: 250px
        line-height: 0
        font-size: 0
    .total
        margin: 25px 0
        em
            font-size: 20px
            font-style: normal

    

.fx 
    padding: 30px 20px
    img
        margin: 10px 0
        max-width: 100%
    dl
        margin-bottom: 20px
    dt
        font-weight: bold
        margin-bottom: 5px
    dd
        span
            background-color: #F6F6F6
            display: inline-block
            padding: 0 15px
            line-height: 22px
            margin-right: 20px    
    
.kz-wrap
    & > *
        display: none
    & > div:first-child
        display: block



// ------------------------------
// (*￣︿￣) app
// ------------------------------


.app
    height: 556px
    +bgj(app, center top) 

.app-link
    padding-top: 355px
    padding-left: 180px
    a
        display: inline-block
        vertical-align: top
        width: 210px
        height: 60px
        margin-bottom: 30px
        line-height: 0
        font-size: 0
        color: rgba(0,0,0,0)
    li
        position: relative
        &:hover
            .app-showbig
                display: block
.app-showbig
    position: absolute
    top: -201px
    background-color: #FFF
    width: 160px
    height: 160px
    border-radius: 10px
    display: none
    padding: 20px
    img
        width: 100%
        height: 100%


// ------------------------------
// (*￣︿￣) ROOM 弹窗
// ------------------------------
#easyDialogBox
    width: 330px
    #date_layer
        padding: 10px
        border: 2px solid $main
        background-color: #FFF
        position: relative
    
    #dateClose
        position: absolute
        right: 10px
        height: 20px
        width: 20px
        text-align: center
        line-height: 20px
        cursor: pointer
        &:hover
            color: $main

    #msj
        color: #999
    .listStyle1_k, #roomAllPrice2
        color: $main
        font-weight: bold

    #roomAllPrice2
        font-size: 16px

    .listStyle1_g
        border-bottom: 1px dotted $line
        font-weight: bold
        margin-bottom: 10px

    .listStyle1_b
        dd
            margin: 10px 0
    .orderT
        a
            display: inline-block
            background-color: $main
            color: #fff
            text-decoration: none
            height: 30px
            line-height: 30px
            padding: 0 15px
            border-radius: 3px


// ------------------------------
// (*￣︿￣) 品牌客栈
// ------------------------------

.brand-banner 
    height: 600px
    margin-bottom: 30px

.brand-filter
    padding: 10px 20px
    background-color: #FFF
    border: 1px solid #e7e7e7
    -ms-box-sizing: border-box
    box-sizing: border-box
    font-size: 16px
    position: relative
    dt
        float: left
        width: 90px
        line-height: 25px
        margin-right: 65px
    dd
        overflow: hidden
        a
            line-height: 25px
            padding: 0 10px
            margin-bottom: 10px
            display: inline-block
            color: #555
        a.on, a:hover
            background-color: $main
            color: #fff
    dd.all

        margin: 0 10px
        position: absolute
        left: 100px


.brand-tj
    padding: 10px 20px
    background-color: #FFF
    border: 1px solid #e7e7e7
    -ms-box-sizing: border-box
    box-sizing: border-box
    margin: 30px auto

.brand-tjtop 
    margin-bottom: 20px
    h2
        font-size: 36px
        line-height: 60px
    p
        font-size: 14px

.brand-tjact
    text-align: right
.brand-tjprice 
    line-height: 1.2
    font-size: 24px
    em
        font-size: 36px
        color: $main
        font-style: normal
.brand-tjgo

    a
        display: inline-block
        vertical-align: top
        +bg(yd)
        width: 250px
        height: 40px
        line-height: 0
        font-size: 0