// ------------------------------
// (*￣︿￣) 
// ------------------------------

.nav
    margin-top: 15px
    margin-left: 45px
    font-size: 16px
    color: #555
    a
        padding: 0 15px
        color: #555
        line-height: 54px
        display: inline-block
        border-bottom: 2px solid #fff
        &:hover, &.on
            color: $main
            border-bottom: 2px solid $main