// ---------------------------
// Mixin管理
// ---------------------------

$fixs: -webket-, -moz-, -ms-, ""
// 属性前缀
=fix($property, $value)
    @each $fix in $fixs
        #{$fix}#{$property}: $value

// 属性值前缀
=fixval($property, $value) 
    @each $fix in $fixs
        #{$property}: #{$fix}#{$value}

// 线性渐变
// linear gradient
=lg($value...)
    +fix(background-image, linear-gradient($value))

// 背景透明度
// background opacity
=bgo($opacity: 0.5, $color: #000)
    background-color: rgba($color, $opacity)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{ie-hex-str(rgba($color, $opacity))},endColorstr=#{ie-hex-str(rgba($color, $opacity))})
    :root & 
        filter: none

// png背景
=bg($name, $position: 0 0, $color: transparent, $repeat: no-repeat) 
    background: $color url('../img/#{$name}.png') $position $repeat

// jpg背景
=bgj($name, $position: 0 0, $color: transparent, $repeat: no-repeat) 
    background: $color url('../img/#{$name}.jpg') $position $repeat
          
// 单行文字溢出时出现省略号，需设定宽度
// text point
=tp($width) 
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: $width

// 多行文字省略号
=mp($row)
    overflow : hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: $row
    -webkit-box-orient: vertical

=br($value...)
    +fix(border-radius, $value)
