// ------------------------------
// (*￣︿￣) 弹窗
// ------------------------------


.tp
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    +bgo(0.5)
    display: none
#con_one_2
    width: 760px
    height: 425px
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    margin: auto
    background-color: #FFF
    border: 1px solid $line
    padding: 15px 0

.G_img_menu
    width: 170px
    height: 419px
    // padding: 0 6px
    padding-top: 6px
    float: left
    // background: #ededec
    .menu_title
        width: 170px
        height: 390px
        overflow: hidden
        li
            width: 170px
            line-height: 28px
            height: 30px
            text-align: center
            // background: url(../img/wd_btn.png) 0 -870px no-repeat
            overflow: hidden
        a
            // color: #88490b
            color: #555
            // width: 84px
            height: 28px
            padding: 0 10px
            margin-bottom: 2px
            display: block
            &:hover
                background: #ff8b2b
                border-bottom: 1px solid #F8CEAE
                color: #fff
                text-decoration: none
                // color: #A85539
                -moz-transition: color 0.2s ease-in
                -webkit-transition: color 0.2s ease-in
                -o-transition: color 0.2s ease-in
                transition: color 0.2s ease-in
        a.choose
            // background: url(../img/wd_btn.png) 0 -839px no-repeat
            background-color: #ff8b2b
            color: #fff
            // font-size: 16px
            // height: 40px
            // line-height: 40px

    .menu_jt
        width: 104px
        height: 22px
        padding: 7px 0 0 14px
        a
            width: 38px
            height: 13px
            display: block
            float: left
        a.jt_up
            background: url(../img/wd_btn.png) -153px -839px no-repeat
            &:hover
                background: url(../img/wd_btn.png) -153px -854px no-repeat
        a.jt_down
            background: url(../img/wd_btn.png) -206px -839px no-repeat
            margin-left: 15px
            &:hover
                background: url(../img/wd_btn.png) -206px -854px no-repeat
                margin-left: 15px

.G_img_main
    width: 536px
    height: 425px
    // float: right
    float: left
    margin-left: 20px

.closet
    position: absolute
    text-decoration: none
    right: 0
    top: -25px
    display: block
    width: 24px
    height: 24px
    line-height: 24px
    overflow: hidden
    background-color: #F2944E
    color: #fff
    text-align: center
.closet 
    i
        font-size: 30px
        font-style: normal

// .tpwrap
//     // width: 930px
//     // height: 380px
//     position: fixed
//     left: 0
//     top: 0
//     right: 0
//     bottom: 0
//     margin: auto
//     background-color: #FFF
//     border: 1px solid $line

// .tp-top
//     height: 40px
//     span
//         float: right
//         width: 40px
//         height: 40px
//         line-height: 0
//         font-size: 0
//         +bg(close, center)
//         cursor: pointer

// .tp-big
//     width: 460px
//     margin: 0 20px

// .tp-pic
//     width: 460px
//     height: 276px
//     margin-bottom: 9px
//     text-align: center
//     line-height: 276px
//     img
//         max-width: 100%
//         max-height: 100%

// .tp-attr, .tp-piccur
//     line-height: 28px
//     em
//         font-style: normal

// .tp-thumb
//     overflow: hidden
// .tp-tab
//     margin-bottom: 14px
//     a
//         display: inline-block
//         line-height: 28px
//         padding: 0 8px
//         +br(3px)
//         &.on, &:hover
//             background-color: #ECECEC

// .tp-list
//     font-size: 0
//     overflow: auto
//     height: 275px
//     width: 405px
//     li
//         display: inline-block
//         width: 120px
//         height: 72px
//         text-align: center
//         position: relative
//         cursor: pointer
//         margin-bottom: 9px
//         margin-right: 9px
//         &.on, &:hover

//             &:after
//                 content: ""
//                 position: absolute
//                 left: 0
//                 width: 116px
//                 height: 68px
//                 border: 2px solid $main
    
//         img
//             max-width: 120px
//             max-height: 72px