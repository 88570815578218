// ------------------------------
// (*￣︿￣) 
// ------------------------------

.breadcrumb
    padding: 15px 0
    margin-bottom: 20px
    border-bottom: 1px solid $line
    i
        font-style: normal
    a
        color: #555
        &:hover
            color: #333

