// ------------------------------
// (*￣︿￣) 
// ------------------------------


.footer
    padding-top: 32px
    padding-bottom: 46px
    border-top: 3px solid $main
    background-color: #525252
    color: #fff
    a
        color: #999

    dl
        width: 155px
        float: left
    dt
        border-left: 3px solid $main
        line-height: 16px
        padding-left: 15px
    dd
        padding-left: 18px
        font-size: 12px
        margin-top: 15px
    
    .code
        float: right
        margin-top: 25px
        margin-right: 98px
    .contact
        margin-top: 25px
        float: right
        +bg(contact)
        width: 336px
        height: 135px
        line-height: 0
        font-size: 0

.friendlink
    background-color: #404040
    padding-bottom: 20px
    color: #fff
    a
        color: #999

    h3
        font-size: 14px
        padding-top: 15px
        padding-bottom: 5px

    .friendlink-list
        a
            margin-right: 40px
            line-height: 24px
            font-size: 12px
            position: relative
            display: inline-block
            &:after
                content: ""
                right: -20px
                top: 5px
                position: absolute
                width: 1px
                height: 14px
                background-color: #999
            &:last-child
                &:after
                    display: none