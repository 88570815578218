// ------------------------------
// (*￣︿￣) 
// ------------------------------

.server
    border: 1px solid $line
    background-color: #FFF
    padding: 10px 0
    margin-bottom: 20px
    dl
        padding: 10px 0
        padding-left: 65px
        border-top: 1px dashed $line
        &:first-child
            border-top: 0
    dt

    dd
        font-size: 12px
        color: #999
@for $i from 1 to 5
    .server-#{$i}
        +bg(s#{$i}, 16px)