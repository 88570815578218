// ------------------------------
// (*￣︿￣) 
// ------------------------------

=pt($m, $ih, $n: 3)
    .pt-list
        margin-left: $m
        margin-bottom: 25px
        width: (1200 - $m*($n - 1)) / $n
        position: relative

        .pt-pic, img
            width: 100%
            height: $ih
        .pt-name
            font-size: 16px
            line-height: 44px
        .pt-price   
            position: absolute
            right: 0
            top: 180px
            +bgo
            color: #fff
            height: 50px
            line-height: 50px
            padding: 0 20px
            font-size: 16px
            +br(50px 0 0 50px)
            em
                font-size: 24px
                font-style: normal
.pt-list
    float: left
    a
        display: block
        color: #555
    img
        width: 100%

