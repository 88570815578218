// ------------------------------
// (*￣︿￣) 
// ------------------------------

$main: #F60
$line: #e7e7e7
$blue: #2680E9
$gray: #999
$orange: #FF6600
$green: #0A9F1F
$red: #FF0000
$green: #2FB061
$pink: #FF4377


@import mixin