// ------------------------------
// (*￣︿￣) 
// ------------------------------

.tac
    text-align: center
.tar
    text-align: right
.vam
    vertical-align: middle
.dib
    display: inline-block
.fl
    float: left
.fr
    float: right
.hide
    display: none
.clear
    &:after
        display: table
        content: ""
        clear: both

.st
    font-family: 'SimSun'